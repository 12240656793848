/*
=========================================================
* Pacific Plaster React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import cfc0 from "assets/images/cfc0.jpg";
import cfc1 from "assets/images/cfc1.jpg";
import cfc2 from "assets/images/cfc2.jpg";
import cfc3 from "assets/images/cfc3.jpg";
import cfc4 from "assets/images/cfc4.jpg";

import cladding0 from "assets/images/cladding0.jpg";
import cladding1 from "assets/images/cladding1.jpg";
import cladding2 from "assets/images/cladding2.jpg";
import cladding3 from "assets/images/cladding3.jpg";
import cladding4 from "assets/images/cladding4.jpg";
import cladding5 from "assets/images/cladding5.jpg";
import cladding6 from "assets/images/cladding6.jpg";
import cladding7 from "assets/images/cladding7.jpg";


import cornice1 from "assets/images/cornice1.jpg";
import cornice2 from "assets/images/cornice2.jpg";
import cornice3 from "assets/images/cornice3.jpg";
import cornice4 from "assets/images/cornice4.jpg";
import cornice5 from "assets/images/cornice5.jpg";
import cornice6 from "assets/images/cornice6.jpg";
import cornice7 from "assets/images/cornice7.jpg";
import cornice8 from "assets/images/cornice8.jpg";
import cornice9 from "assets/images/cornice9.jpg";
import cornice10 from "assets/images/cornice10.jpg";

import plaster from "assets/images/plaster.jpg";
import plaster2 from "assets/images/plaster2.jpg";
import plaster3 from "assets/images/plaster3.jpg";
import plaster4 from "assets/images/plaster4.jpg";
import plaster5 from "assets/images/plaster5.jpg";
import plaster6 from "assets/images/plaster6.jpg";

import gyprock01 from "assets/images/gyprock01.jpg";
import gyprock1 from "assets/images/gyprock.jpg";
import gyprock2 from "assets/images/gyprock2.jpg";
import gyprock3 from "assets/images/gyprock3.jpg";
import gyprock4 from "assets/images/gyprock4.jpg";


import aluminumOffice from "assets/images/aluminumOffice.jpg";
import aluminumOffice2 from "assets/images/aluminumOffice2.jpg";
import aluminumOffice3 from "assets/images/aluminumOffice3.jpg";
import aluminumOffice4 from "assets/images/aluminumOffice4.jpg";
import aluminumOffice5 from "assets/images/aluminumOffice5.jpg";
import aluminumOffice6 from "assets/images/aluminumOffice6.jpg";

import decking0 from "assets/images/decking0.jpg";
import decking01 from "assets/images/decking01.jpg";
import decking02 from "assets/images/decking02.jpg";
import decking03 from "assets/images/decking03.jpg";
import decking04 from "assets/images/decking04.jpg";
import decking05 from "assets/images/decking05.jpg";
import decking06 from "assets/images/decking06.jpg";
import decking07 from "assets/images/decking07.jpg";
import decking08 from "assets/images/decking08.jpg";


import decking1 from "assets/images/decking.jpg";
import decking2 from "assets/images/decking2.jpg";
import decking3 from "assets/images/decking3.jpg";
import decking4 from "assets/images/decking4.jpg";
import decking5 from "assets/images/decking5.jpg";

import pergola1 from "assets/images/pergola.jpg";
import pergola0 from "assets/images/pergola0.jpg";
import pergola01 from "assets/images/pergola01.jpg";
import pergola02 from "assets/images/pergola02.jpg";
import pergola03 from "assets/images/pergola03.jpg";
import pergola04 from "assets/images/pergola04.jpg";
import pergola05 from "assets/images/pergola05.jpg";


import woodDoor0 from "assets/images/woodDoor0.jpg";
import woodDoor1 from "assets/images/woodDoor01.jpg";
import woodDoor2 from "assets/images/woodDoor02.jpg";
import woodDoor3 from "assets/images/woodDoor03.jpg";
import woodDoor4 from "assets/images/woodDoor04.jpg";
import woodDoor5 from "assets/images/woodDoor05.jpg";
import woodDoor6 from "assets/images/woodDoor06.jpg";
import woodDoor7 from "assets/images/woodDoor2.jpg";

import arch1 from "assets/images/arch1.jpg";
import arch2 from "assets/images/arch2.jpg";
import arch3 from "assets/images/arch3.jpg";
import skirt0 from "assets/images/skirt0.jpg";
import skirt01 from "assets/images/skirt01.jpg";
import skirt02 from "assets/images/skirt02.jpg";

import woodOffice from "assets/images/woodOffice.jpg";
import woodOffice2 from "assets/images/woodOffice2.jpg";
import woodOffice3 from "assets/images/woodOffice3.jpg";

import woodDoorLock from "assets/images/woodDoorLock.jpg";
import woodDoorLock2 from "assets/images/woodDoorLock2.jpg";


import carpentry1 from "assets/images/carpentry1.jpg";
import carpentry2 from "assets/images/carpentry2.jpg";
import carpentry3 from "assets/images/carpentry3.jpg";
import carpentry4 from "assets/images/carpentry4.jpg";
import carpentry5 from "assets/images/carpentry5.jpg";
import carpentry6 from "assets/images/carpentry6.jpg";

import installhandler0 from "assets/images/installhandler0.jpg";
import installhandler01 from "assets/images/installhandler01.jpg";
import installhandler02 from "assets/images/installhandler02.jpg";
import installhandler03 from "assets/images/installhandler03.jpg";
import installhandler04 from "assets/images/installhandler04.jpg";
import installhandler05 from "assets/images/installhandler05.jpg";



const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "Compressed Fibre Cement (CFC)",
    description: "We offer a variety of Installation Compressed Fiber Cement services.",
    items: [
      {
        image: cfc0,
        name: "Compressed Fibre Cement (CFC)",
        count: 4,
        route: "/sections/navigation/navbars",
      },
      {
        image: cfc1,
        name: "Compressed Fibre Cement (CFC)",
        count: 2,
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: cfc2,
        name: "Compressed Fibre Cement (CFC)",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cfc3,
        name: "Compressed Fibre Cement (CFC)",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cfc4,
        name: "Compressed Fibre Cement (CFC)",
        count: 3,
        route: "/sections/navigation/pagination",
      },
    ],
  },
  {
    title: "Cladding",
    description: "We offer a variety of Installation Cladding services.",
    items: [
      {
        image: cladding0,
        name: "Cladding",
        count: 4,
        route: "/sections/navigation/navbars",
      },
      {
        image: cladding1,
        name: "Cladding",
        count: 2,
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: cladding2,
        name: "Cladding",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cladding3,
        name: "Cladding",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cladding4,
        name: "Cladding",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cladding5,
        name: "Cladding",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cladding6,
        name: "Cladding",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: cladding7,
        name: "Cladding",
        count: 3,
        route: "/sections/navigation/pagination",
      },

    ],
  },
  {
    title: "Plaster Gyprock Cornice",
    description: "We do all kinds of plastering and painting for your home.",
    items: [
      {
        image: plaster,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: plaster2,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: plaster3,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: plaster4,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: plaster5,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
     
      {
        image: cornice1,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: cornice2,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: cornice3,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: cornice4,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: cornice5,
        name: "Cornice",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: cornice6,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: cornice7,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: cornice8,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: cornice9,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: cornice10,
        name: "Cornice",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: plaster6,
        name: "Plaster Gyprock",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: gyprock01,
        name: "Plaster Gyprock",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: gyprock1,
        name: "Plaster Gyprock",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: gyprock2,
        name: "Plaster Gyprock",
        count: 14,
        route: "/sections/page-sections/Cornice",
      },
      {
        image: gyprock3,
        name: "Plaster Gyprock",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: gyprock4,
        name: "Plaster Gyprock",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
    ],
  },
  {
    title: "Carpentry and Painting",
    description: "We have a wide range of carpentry and painting services.",
    items: [
      {
        image: carpentry1,
        name: "carpentry",
        count: 4,
        route: "/sections/navigation/navbars",
      },
      {
        image: carpentry2,
        name: "carpentry",
        count: 2,
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: carpentry3,
        name: "carpentry",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: carpentry4,
        name: "carpentry",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: carpentry5,
        name: "carpentry",
        count: 3,
        route: "/sections/navigation/pagination",
      },
      {
        image: carpentry6,
        name: "carpentry",
        count: 3,
        route: "/sections/navigation/pagination",
      },
    ],
  },
  {
    title: "Wood Doors",
    description: "We do all kinds of wood doors.",
    items: [
      {
        image: woodDoor0,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoor1,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoor2,
        name: "Wood Doors",
        count: 2,
        route: "/sections/elements/avatars",
      },
      {
        image: woodDoor3,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoor4,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoor5,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoor6,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoor7,
        name: "Wood Doors",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: woodDoorLock,
        name: "Wood Doors Lock",
        count: 2,
        route: "/sections/elements/dropdowns",
      },
      {
        image: woodDoorLock2,
        name: "Wood Doors Lock",
        count: 2,
        route: "/sections/elements/toggles",
      },
      
    ],
  },
  {
    title: "Architrave & Skirting",
    description: "We do all kinds of architrave & skirting.",
    items: [
      {
        image: arch1,
        name: "Architrave",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: arch2,
        name: "Architrave",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: arch3,
        name: "Architrave",
        count: 2,
        route: "/sections/elements/avatars",
      },
      {
        image: skirt0,
        name: "Skirting",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: skirt01,
        name: "Skirting",
        count: 6,
        route: "/sections/elements/buttons",
      },
      {
        image: skirt02,
        name: "Skirting",
        count: 6,
        route: "/sections/elements/buttons",
      },
    ],
  },
  {
    title: "Decking & Pergola",
    description: "We do all kinds of decking and pergola.",
    items: [
      {
        image: decking0,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking01,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking02,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking03,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking04,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking05,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking06,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking07,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking08,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking1,
        name: "Decking",
        count: 4,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking2,
        name: "Decking",
        count: 3,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: decking3,
        name: "Decking",
        count: 2,
        route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: decking4,
        name: "Decking",
        count: 2,
        route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: decking5,
        name: "Decking",
        count: 2,
        route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: pergola1,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: pergola0,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: pergola01,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: pergola02,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: pergola03,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: pergola04,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
      {
        image: pergola05,
        name: "Pergola",
        count: 5,
        route: "/sections/attention-catchers/modals",
      },
    ],
  },
  {
    title: "Aluminium Partition Systems",
    description: "Aluminium Partition Systems is a great choice for your office.",
    items: [
      {
        image: aluminumOffice,
        name: "Aluminium Partition Systems",
        count: 6,
        route: "/sections/input-areas/forms",
      },
      {
        image: aluminumOffice2,
        name: "Aluminium Partition Systems",
        count: 8,
        route: "/sections/input-areas/forms",
      },
      {
        image: aluminumOffice3,
        name: "Aluminium Partition Systems",
        count: 3,
        route: "/sections/input-areas/forms",
      },
      {
        image: aluminumOffice4,
        name: "Aluminium Partition Systems",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
      {
        image: aluminumOffice5,
        name: "Aluminium Partition Systems",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
      {
        image: aluminumOffice6,
        name: "Aluminium Partition Systems",
        count: 6,
        route: "/sections/input-areas/forms",
      },
    ],
  },
  {
    title: "Set up handler services",
    description: "We offer installation handler services.",
    items: [
      {
        image: installhandler0,
        name: "Configure handler services",
        count: 6,
        route: "/sections/input-areas/forms",
      },
      {
        image: installhandler01,
        name: "Set up handler services",
        count: 8,
        route: "/sections/input-areas/forms",
      },
      {
        image: installhandler02,
        name: "Set up handler services",
        count: 3,
        route: "/sections/input-areas/forms",
      },
      {
        image: installhandler03,
        name: "Install handler services",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
      {
        image: installhandler04,
        name: "Install handler services",
        count: 6,
        route: "/sections/input-areas/inputs",
      },
      {
        image: installhandler05,
        name: "Install handler services",
        count: 6,
        route: "/sections/input-areas/forms",
      },
    ],
  },
  
];

