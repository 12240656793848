/**
=========================================================
* Pacific Plaster React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Pacific Plaster React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
import HandymanIcon from '@mui/icons-material/Handyman';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CallIcon from '@mui/icons-material/Call';

// Pages
import AboutUs from "pages/LandingPages/AboutUs";
import ContactUs from "pages/LandingPages/ContactUs";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";

// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Navbars from "layouts/sections/navigation/navbars";
// import Inputs from "layouts/sections/input-areas/inputs";
import Service from 'pages/LandingPages/Service/index';


const routes = [
  {
    name: "About",
    icon: <ContactPageIcon/>,
    route: "/about",
    component: <AboutUs />,
    
  },
  {
    name: "services",
    icon: <Icon>view_day</Icon>,
    route: "/service",
    component: <Service />,
  },
  // {
  //   name: "blog",
  //   icon: <Icon>article</Icon>,
  //   collapse: [
  //     {
  //       name: "Cornice/ Gyprock/ Plaster",
  //       description: "Cornice/ Gyprock/ Plaster",
  //       route: "/blog/cornice-gyprock-plaster",
  //     },
  //     {
  //       name: "Carpentry Services",
  //       description: "Carpentry Services",
  //       route: "/blog/carpentry-services",
  //     },
  //     {
  //       name: "Office Partitions",
  //       description: "Office Partitions",
  //       route: "/blog/office-partitions",
  //     },
  //     {
  //       name: "Pergola, Decking, Paving",
  //       description: "Pergola, Decking, Paving",
  //       route: "/blog/pergola-decking-paving",
  //     },
  //   ],
  // },
  {
    name: "contact",
    icon: <CallIcon />,
    route: "/contact",
    component: <ContactUs />,
  },
];

export default routes;
